import * as Sentry from "@sentry/vue";


export default defineNuxtPlugin({
  name: "sentry",
  enforce: "post",
  async setup(nuxtApp) {
    const currentHost = window.location.hostname; 
    if (currentHost !== "localhost") {
      const config = useRuntimeConfig();
      Sentry.init({
        app: nuxtApp.vueApp,
        autoSessionTracking: true,
        dsn: config.public.SENTRY_API_KEY,
        environment:
          currentHost === "rwa-estate.com" ? "production" : "development",
        integrations: [
          Sentry.browserTracingIntegration(),
          Sentry.replayIntegration({
            maskAllText: false,
            blockAllMedia: false,
          }),
        ],
        tracePropagationTargets: [
          "https://stage.rwa-estate.com",
          "https://www.rwa-estate.com",
          /^\/api\//,
        ],
        hooks: ["activate", "create", "destroy", "mount", "update"],
        tracesSampleRate: 1.0,
        beforeSend(event, hint) {
          if (event.exception) {
            console.error(
              `[Exeption handled by Sentry]: (${hint.originalException})`,
              {
                event,
                hint,
              }
            );
          }
          return event;
        },
        replaysSessionSampleRate: 0.1,
        replaysOnErrorSampleRate: 1.0,
      });
    }
  },

  env: {
    islands: true,
  },
});
