<template>
  <div class="base-select-wrapper">
    <!-- <div class="petals">
      <div
        v-for="(item, index) in estateTypes"
        :class="[
          'petal',
          `petal-${item.title.toLowerCase()}`,
          { 'petal-active': index === currentEstateType },
        ]"
        @click="setEstateSelectType(index)"
      >
        <span class="petal-text font--b5">{{ item.title }}</span>
        <q-badge
          v-if="item.badge"
          class="font--b6 text-black-monochrome"
          label="Coming Soon"
        />
      </div>
    </div> -->
    <div class="base__select">
      
      <div class="base__select-selects">
        <button
          v-for="(component, index) in estateTypes[currentEstateType].component"
          :key="index"
          class="custom-select"
          @click="
            component.click
              ? component.click(index + 10)
              : pickSelect(index + 1)
          "
          @blur="component.blur ? component.blur($event) : pickSelect(0)"
        >
          <component
            :is="component.c"
            :location-filter="locationFilter"
            :current-select-card="currentSelectCard"
            :contry-arr="contryArr"
            :country-names="countryNames"
            :pick-location-checkbox="pickLocationCheckbox"
            :prices="prices"
            :labels="labels"
            :min-price="minPrice"
            :max-price="maxPrice"
            :price-filter="priceFilter"
            :price-input="priceInput"
            :is-filters-updating="isFiltersUpdating"
            :update-price="updatePrice"
            :set-price-range="setPriceRange"
            :property-filter="propertyFilter"
            :pick-property-filter="pickPropertyFilter"
            @is-user-in-price-range="setUserInPriceRange"
          />
          <base-separator
            v-if="
              index !== estateTypes[currentEstateType]?.component?.length - 1
            "
          />
        </button>
      </div>
      <div class="base__select-butt">
        <base-button
          class="hover-scale-light"
          icon-name="base/MagnifyingGlass"
          round
          size="round-xs"
          style="gap: 0"
          @click="handleSearch"
        />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { apiPost } from "~/services/api";
import type { IPriceRange } from "~/types/filters";
import getRoute from "~/utilities/configs/apiMap";
import { countryNames } from "~/utilities/constants/countryCodes";

import { serializeObject } from "~/utilities/helpers/query/serializeObject";
import { cleanQuery } from "~/utilities/helpers/query/cleanQuery";
import {
  filtersStore,
  type FetchFiltersParameters,
} from "~/store/search_filters";
import eventBus from "~/utilities/composables/eventBus";
import type { UnitStatisticsDTO } from "~/services/swagger/Api";
import type { DefineComponent } from "vue";
import type {
  LocationFilterProps,
  SelectCardProps,
  PriceRangeProps,
} from "~/components/base/custom-select/types";
import countryCard from "~/components/base/custom-select/country/index.vue";
import stageCard from "~/components/base/custom-select/stage/index.vue";
import tokenCard from "~/components/base/custom-select/token/index.vue";

// Type Definitions
// interface Emits {
//   (e: "update:modelValue", value: string): void;
//   (e: "enter"): void;
//   (e: "blur"): void;
//   (e: "search", value: any): void;
//   (e: "blank"): void;
// }

// interface ICheckboxRange {
//   label: string;
//   value: {
//     gte: number;
//     lte: number;
//   };
//   amount: number;
// }

const pickSelect = (type: number) => {
  currentSelectCard.value = currentSelectCard.value === type ? 0 : type;
};

const handlePriceRangeBlur = (event: any) => {
  if (isUserInPriceRange.value) {
    event.preventDefault();
    priceRangeRef.value?.focus();
  } else {
    priceRangeRef.value?.blur();
    pickSelect(0);
  }
};

const { t } = useI18n();

//tabs

enum CardsType {
  Prop = "Property",
  Land = "Land",
  Comm = "Commercial",
}

type EstateComponent =
  | DefineComponent<any, any, any>
  | DefineComponent<LocationFilterProps, any, any>
  | DefineComponent<SelectCardProps, any, any>
  | DefineComponent<PriceRangeProps, any, any>;

type EstateSelectType = {
  title: CardsType;
  component: {
    c: EstateComponent;
    click?: Function;
    blur?: Function;
  }[];
  badge?: boolean;
};

const estateTypes: EstateSelectType[] = [
  {
    title: CardsType.Prop,
    component: [
      { c: countryCard },
      { c: stageCard },
      { c: tokenCard, blur: handlePriceRangeBlur },
    ],
  },
  // {
  //   title: CardsType.Land,
  //   badge: true,
  //   component: [
  //     { c: countryCard },
  //     { c: acreageCard },
  //     { c: tokenCard, blur: handlePriceRangeBlur },
  //   ],
  // },
  // {
  //   title: CardsType.Comm,
  //   badge: true,
  //   component: [
  //     { c: countryCard },
  //     { c: stageCard },
  //     { c: tokenCard, blur: handlePriceRangeBlur },
  //   ],
  // },
];

const currentEstateType = ref(0);

// const setEstateSelectType = (index: number) => {
//   if (estateTypes[index].badge) return;
//   currentEstateType.value = index;
// };

// Emits
// const emit = defineEmits<Emits>();

// Stores
const useFiltersStore = filtersStore();

// Refs
const currentSelectCard = ref(0);
const priceRangeValue = ref<IPriceRange | null>();
const isFiltersUpdating = ref(false);
// const checkboxRange = ref<ICheckboxRange[]>([]);
const priceRangeRef = ref<HTMLElement | null>();
const isUserInPriceRange = ref(false);
const locationFilter = ref<string[]>([]);
const propertyFilter = ref<{ label: string; key: string }>();
const priceFilter = ref<string[]>([]);
// const currentPriceCheckbox = ref<number[]>([]);

// Computed Properties
const labels = computed(() =>
  priceRangeValue.value?.range ? Object.values(priceRangeValue.value.range) : []
);

const prices = computed(() =>
  priceRangeValue.value?.range
    ? Object.keys(priceRangeValue.value.range).map(Number)
    : []
);

const minPrice = computed(() => priceRangeValue.value?.priceMin);
const maxPrice = computed(() => priceRangeValue.value?.priceMax);
const priceInput = computed(() => {
  return priceFilter.value?.length
    ? priceFilter.value.length > 1
      ? priceFilter.value[0] + ` +${priceFilter.value.length - 1}`
      : priceFilter.value.join("")
    : t("search_filters.fr_just_price");
});

// Methods
const updateFilters = async (
  isInitial?: boolean,
  ...args: Partial<FetchFiltersParameters>
) => {
  isFiltersUpdating.value = false;

  const fetchFiltersPromise = useFiltersStore.updateEstateFilters(
    isInitial,
    ...args
  );

  const loaderTimeout = setTimeout(() => {
    isFiltersUpdating.value = true;
  }, 1000);

  await fetchFiltersPromise;

  clearTimeout(loaderTimeout);
  isFiltersUpdating.value = false;

  priceRangeValue.value = useFiltersStore.priseRange;
  // eventBus.emit("update-price-range", [
  //   priceRangeValue.value?.priceMin,
  //   priceRangeValue.value?.priceMax,
  // ]);
};

const updateCountryArr = async (
  filters: any,
  currentStats?: Record<string, number>
) => {
  try {
    let countryObj: Record<string, number>;
    if (!currentStats) {
      const response = await apiPost({
        url: getRoute({ endpont: "get_filter_stats_estate" }),
        body: cleanQuery(filters),
      });
      if (!response?.data.value) return;
      const res = response.data.value as UnitStatisticsDTO;
      countryObj = res.country as Record<string, number>;
    } else {
      countryObj = currentStats;
    }

    contryArr.value = transformObject(countryNames)
      .map((item) => {
        item.amount = countryObj[item.code] ?? 0;
        return item;
      })
      .filter((item) => item.amount !== 0)
      .sort((a, b) => a.name.localeCompare(b.name))
      .sort((a, b) => b.amount - a.amount);
  } catch (error) {
    throw error;
  }
};

const setPriceRange = async (obj: {
  tokenPrice: { gte: number; lte: number };
}) => {
  priceFilter.value = [`${obj.tokenPrice.gte} to ${obj.tokenPrice.lte}`];
  // currentPriceCheckbox.value.length = 0;
  updateCountryArr(obj);
};

const pickPropertyFilter = (filter: { label: string; key: string }) => {
  propertyFilter.value = filter;
  const filters = getFilters();
  updateCountryArr(cleanQuery(filters));
  updateFilters(false, cleanQuery(filters));
};

const handleSearch = () => {
  pickSelect(0);
  const filters = getFilters();
  const localePath = useLocalePath();
  const route = localePath(
    `/directory?${serializeObject(cleanQuery(filters))}`
  );
  navigateTo(route);
};

const pickLocationCheckbox = (index: number) => {
  const key = contryArr.value[index].code;
  if (!countryNames[key]) return;
  if (locationFilter.value.includes(key)) {
    locationFilter.value = locationFilter.value.filter((item) => item !== key);
  } else {
    locationFilter.value.push(key);
  }
  const filters = getFilters();
  updateFilters(false, cleanQuery(filters));
};

const transformObject = (obj: any) => {
  return Object.keys(obj).map((key) => ({
    code: key,
    name: obj[key],
    amount: 0,
  }));
};

const getFilters = () => {
  const [maxNum, minNum] = processArray(priceFilter.value);
  const filters = {
    countryId: locationFilter.value,
    ["tokenPrice[gte]"]: minNum,
    ["tokenPrice[lte]"]: maxNum,
    stage: propertyFilter.value?.key ? [propertyFilter.value?.key] : null,
  };
  return filters;
};

const processArray = (arr: string[]): [number | null, number | null] => {
  const extractNumbers = (str: string): number[] => {
    return str
      .split(/[^0-9kK.]+/)
      .map((part) => {
        const cleanStr = part.replace(/[^\dKk.]/g, "");
        if (cleanStr.toLowerCase().endsWith("k")) {
          return parseFloat(cleanStr) * 1000;
        }
        return parseFloat(cleanStr);
      })
      .filter((num) => !isNaN(num));
  };

  const numbers = arr.flatMap(extractNumbers);
  let maxNum: number | null;
  let minNum: number | null;
  if (numbers.length === 0) {
    maxNum = null;
    minNum = null;
    return [maxNum, minNum];
  }
  if (numbers.length === 1) {
    maxNum = Math.max(...numbers);
    minNum = 0;
    return [maxNum, minNum];
  }
  maxNum = Math.max(...numbers);
  minNum = Math.min(...numbers);
  return [maxNum, minNum];
};

const updatePrice = () => {
  return function (ctx: () => void) {
    eventBus.on("update-price-range", ctx);
  };
};

// const gatherPriceCheckBoxValues = () => {
//   function roundToThousands(value: number): number {
//     return Math.round(value / 1000);
//   }
//   priceRangeValue.value?.range.forEach((p, index) => {
//     const isFirst = index === 0;
//     const obj: ICheckboxRange = {
//       label: isFirst
//         ? `Under $${roundToThousands(p.price)}k`
//         : `$${roundToThousands(priceRangeValue.value?.range[index - 1].price || 0)}k to $${roundToThousands(p.price)}k`,
//       value: {
//         gte: isFirst ? 0 : p.price,
//         lte: isFirst
//           ? p.price
//           : priceRangeValue.value?.range[index + 1]?.price ||
//             useFiltersStore.priseRange?.priceMax ||
//             +Infinity,
//       },
//       amount: p.count,
//     };
//     checkboxRange.value.push(obj);
//   });
// };

const contryArr = ref(transformObject(countryNames));

const setUserInPriceRange = (val: boolean) => {
  isUserInPriceRange.value = val;
};

// Lifecycle Hooks
onMounted(async () => {
  await updateFilters(true, { status: ["published",'sold_out'] });
  updateCountryArr({}, useFiltersStore.currentStats?.country);
});
</script>

<style scoped lang="scss">
@import url(~/assets/styles/custom-select/index.scss);
</style>
